import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, Outlet } from 'react-router-dom';
import {
  DidomiFeatureHeader,
  DidomiNavTabContent,
  DidomiNavTabContents,
  DidomiNavTabHeader,
  DidomiNavTabHeading,
  DidomiNavTabs,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useAccessPolicies } from '../hooks/data/access-policies/useAccessPolicies.hook';
import { useSPARouter } from '@didomi/utility-react';
import { dashboardRoute } from '../constants/external-routes.constants';

const getActiveRouteClass = ({ isActive }: { isActive: boolean }) => {
  return isActive ? 'didomi-active-tab' : '';
};

export const AuthenticationSettingsLayout = (): JSX.Element => {
  const { navigateTo } = useSPARouter();
  const { isLoading: isAccessPoliciesLoading, hasAdminAccess } = useAccessPolicies();

  useLayoutEffect(() => {
    if (isAccessPoliciesLoading) return;

    if (!hasAdminAccess) navigateTo(dashboardRoute);
  }, [hasAdminAccess, isAccessPoliciesLoading, navigateTo]);

  return (
    <section className="p-l pb-20 h-full box-border !flex flex-col">
      <div className="flex mb-8 flex-col">
        <DidomiFeatureHeader titleText="Authentication" className="w-full" style={{ display: 'block' }}>
          <div slot="description">Manage authentication methods for your preferences and requests widgets.</div>
          <div id="authentication-settings-layout-actions" className="flex gap-xl" slot="actions" data-skeleton></div>
        </DidomiFeatureHeader>
        <DidomiNavTabs className="flex-1 flex flex-col">
          <DidomiNavTabHeading data-skeleton className={tx({ '!border-0': false })}>
            <DidomiNavTabHeader>
              <DidomiNavTabHeader disabledTooltip="Sorry you don’t have access to this content, please contact your administrator">
                <NavLink data-testid="nav-link-user-requests" className={getActiveRouteClass} to={'/authentication/manage'}>
                  Manage
                </NavLink>
              </DidomiNavTabHeader>

              <DidomiNavTabHeader disabled disabledTooltip="Accessible soon..." data-testid="nav-tab-header-widgets">
                <NavLink data-testid="nav-link-widgets" className={getActiveRouteClass} to={'/authentication/configuration'}>
                  Configuration
                </NavLink>
              </DidomiNavTabHeader>
            </DidomiNavTabHeader>
          </DidomiNavTabHeading>
          <DidomiNavTabContents data-skeleton className={tx('flex-1 flex flex-col mt-s')}>
            <DidomiNavTabContent className="flex-1">
              <Outlet />
            </DidomiNavTabContent>
          </DidomiNavTabContents>
        </DidomiNavTabs>
      </div>
    </section>
  );
};

export const ActionsSlot: React.FunctionComponent<{ children?: React.ReactElement }> = ({ children }) => {
  const element = document.querySelector('#authentication-settings-layout-actions');

  if (!element) return null;

  return ReactDOM.createPortal(children, element);
};
