import React from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { ErrorState } from '@components';
import { AuthenticationSettingsLayout } from './layouts/AuthenticationSettingsLayout';
import { AuthenticationSettingsManage } from './pages/AuthenticationSettingsManage';
import { AuthenticationSettingsConfiguration } from './pages/AuthenticationSettingsConfiguration';

export const routes: RouteObject[] = [
  {
    path: '',
    errorElement: <ErrorState />,
    children: [
      {
        element: <AuthenticationSettingsLayout />,
        path: 'authentication',
        children: [
          {
            path: 'manage',
            element: <AuthenticationSettingsManage />,
          },
          {
            path: 'configuration',
            element: <AuthenticationSettingsConfiguration />,
          },
        ],
      },
    ],
  },
];

export const router = ({ basename } = { basename: undefined }) => createBrowserRouter(routes, { basename });
