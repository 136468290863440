/**
 * This function checks if an array has duplicate elements based on a specific key.
 *
 * @example
 * const arr = [{ id: 1, name: "John" }, { id: 2, name: "Mary" }, { id: 1, name: "Smith" }];
 * const key = "id";
 *
 * hasDuplicates(arr, key);
 * // Expected output: true, because there are two elements with the same `id` key.
 */
export const hasDuplicates = <T>(arr: T[], key: keyof T): boolean =>
  Object.values(arr.reduce((acc, provider) => ({ ...acc, [provider[key as string]]: (acc[provider[key as string]] || 0) + 1 }), {})).some(
    count => count >= 2,
  );
