import React from 'react';
import { DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButton, DidomiIcon } from '@didomi/ui-atoms-react';

interface ChangeDefaultProviderSuccessModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const ChangeDefaultProviderSuccessModal = ({ isOpen, handleClose }: ChangeDefaultProviderSuccessModalProps): JSX.Element => {
  return (
    <DidomiModal className="relative" isOpen={isOpen} permanent="true" variant="slim" data-testid={'change-default-provider-modal'}>
      <DidomiIcon
        style={{ marginBottom: '16px' }}
        onClick={() => {
          handleClose();
        }}
        name="success"
      />
      <DidomiModalHeader
        variant="warning"
        modalTitle={'Placeholder'}
        modalDescription={
          // eslint-disable-next-line max-len
          'We highly recommend checking your widgets to ensure that the new provider has been correctly applied.'
        }
      >
        <div slot="modal-title">
          <h3 className="h3 text-primary-blue-6 mb-2" style={{ marginBottom: '8px' }}>
            Default provider <br /> has been updated successfully.
          </h3>
        </div>
      </DidomiModalHeader>
      <DidomiModalActions>
        <DidomiButton data-testid="apply-button" onClick={() => handleClose()}>
          Close
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
