import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { AuthProvider } from '../../../interfaces/auth-provider.interface';
import { apiRoutes } from '@constants';
import { PaginatedResponse } from '@interfaces';

export type UpdateRequestPayload = {
  id: string;
} & Partial<AuthProvider>;

export const useUpdateAuthProvider = (options?: UseMutationOptions<AxiosResponse<AuthProvider>, AxiosError, UpdateRequestPayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patch = async (data: UpdateRequestPayload) => {
    const { id, ...payload } = data;

    return await axiosWithInterceptors.patch<AuthProvider>(`${apiRoutes.authProviders}/${id}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<AuthProvider>, AxiosError, UpdateRequestPayload>(patch, {
    ...options,
    onSuccess: (updatedEntity: AxiosResponse<AuthProvider>, variables, context) => {
      // this will update all queries/caches that start with 'request'
      const dataKey = ['auth-providers'];

      queryClient.setQueriesData(dataKey, (data: PaginatedResponse<AuthProvider> | Request) => {
        if ('data' in data && Array.isArray(data.data)) {
          return {
            ...data,
            data: data.data.map(entity => (entity.id === variables.id ? { ...entity, ...updatedEntity.data } : entity)),
          };
        } else if ('id' in data && data.id === updatedEntity.data.id) {
          return { ...data, ...updatedEntity.data };
        }
      });

      if (options?.onSuccess) {
        options.onSuccess(updatedEntity, variables, context);
      }
    },
  });
};
