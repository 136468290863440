import React, { useEffect } from 'react';
import { connect } from '@didomi/ui-foundation';
import { useActiveOrganization, UtilityProvider } from '@didomi/utility-react';
import * as utility from '@didomi/utility';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { onReactQueryError, reportSpaError } from '@utils';
import { router } from './routes';
import { RouterProvider } from 'react-router-dom';

class ApiError extends Error {
  request_metadata;

  constructor(error) {
    super(error.message);
    this.name = 'ApiError';

    this.request_metadata = {
      method: error.config?.method?.toUpperCase(),
      url: error.config?.url,
      error_message: error.response?.data?.message,
    };
  }
}

// Handle queries errors
const queryCache = new QueryCache({
  onError: (error: Error) => reportSpaError(new ApiError(error)),
});

// Handle mutations errors
const mutationCache = new MutationCache({
  onError: (error: Error) => reportSpaError(new ApiError(error)),
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  logger: {
    error: onReactQueryError,
    log: () => {},
    warn: () => {},
  },
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      cacheTime: 0,
    },
  },
});
const isDevEnvironment = CONFIG.environment === 'development';
const isStagingEnvironment = CONFIG.environment === 'staging';

if (isDevEnvironment || isStagingEnvironment) {
  // makeServer();
}

// Setup Twind for authentication settings app only
connect('.product-settings-root');

const OrganizationRouterProvider = () => {
  const { organizationId } = useActiveOrganization();
  return <RouterProvider router={router({ basename: `/${organizationId}/product-settings` })} />;
};

const Root = () => {
  useEffect(() => {
    // Clear the queryClient cache when the app is unmounted
    return () => queryClient.clear();
  }, []);

  return (
    <UtilityProvider utility={utility}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <OrganizationRouterProvider />
        <ReactQueryDevtools initialIsOpen={isDevEnvironment} />
      </QueryClientProvider>
    </UtilityProvider>
  );
};

export { Root };
