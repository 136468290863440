import { useQuery } from '@tanstack/react-query';
import { PaginatedResponse } from '@interfaces';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes, DEFAULT_CACHE_TIME } from '@constants';
import { AuthProvider } from '../../../interfaces/auth-provider.interface';

/**
 *  this hook provides a way to fetch and cache the list of authentication providers for the active organization
 */
export const useAuthProviders = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchRequests = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<AuthProvider>>(apiRoutes.authProviders, {
      params: {
        organization_id: organizationId,
        $sort: { created_at: -1 },
      },
    });

    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<AuthProvider>>(
      [
        'auth-providers',
        {
          organization_id: organizationId,
        },
      ],
      fetchRequests,
      {
        cacheTime: DEFAULT_CACHE_TIME,
        staleTime: 0,
      },
    ),
  };
};
