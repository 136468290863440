import React from 'react';
import { DidomiLoader, DidomiModal, DidomiModalHeader } from '@didomi/ui-atoms-react';

interface LoadingModalProps {
  title: string;
  subTitle?: string;
  isOpen: boolean;
}

export const LoadingModal = ({ isOpen, title, subTitle = 'Please wait' }: LoadingModalProps) => (
  <DidomiModal className="relative" isOpen={isOpen} permanent="true" variant="slim">
    <div className="mb-s flex justify-center items-center">
      <DidomiLoader />
    </div>
    <DidomiModalHeader modalTitle={title} modalSubTitle={subTitle} style={{ whiteSpace: 'pre' }} />
  </DidomiModal>
);
