import React, { useEffect } from 'react';
import { DidomiErrorState } from '@didomi/ui-atoms-react';
import { useRouteError } from 'react-router-dom';
import { reportSpaError } from '@utils';

export const ErrorState = () => {
  const error: any = useRouteError();

  useEffect(() => {
    if (error) {
      reportSpaError(error);
    }
  }, [error]);

  return (
    <div className="h-full p-l">
      <DidomiErrorState illustrationName="content-loading-error" className="h-full">
        <div slot="title">There seems to be an error</div>
        <div slot="description">Please try refreshing the page or contact our support.</div>
      </DidomiErrorState>
    </div>
  );
};
