import React, { useState } from 'react';
import {
  DidomiChip,
  DidomiHintbox,
  DidomiRadio,
  DidomiRadioGroup,
  DidomiSelectionBlock,
  DidomiSkeleton,
  DidomiButton,
} from '@didomi/ui-atoms-react';
import { useAuthProviders } from '../hooks/data/auth-providers/useAuthProviders.hook';
import { AuthProviderType } from '@enums';
import { ChangeDefaultProviderModal } from '../modals/ChangeDefaultProviderModal';
import { useUpdateAuthProvider } from '../hooks/data/auth-providers/useUpdateAuthProvider.hook';
import { ChangeDefaultProviderSuccessModal } from '../modals/ChangeDefaultProviderSuccessModal';
import { LoadingModal } from '../modals/LoadingModal';
import { hasDuplicates } from '@utils';
import { AUTH_TYPE_LABEL_MAP } from '../constants/auth.constants';
import { ActionsSlot } from '../layouts/AuthenticationSettingsLayout';

interface ProviderUpdatePayload {
  id: string;
  type: AuthProviderType | null;
}

export const AuthenticationSettingsManage = (): JSX.Element => {
  const [hasProviderChanged, setHasProviderChanged] = useState(false);
  const { data, refetch: refetchAuthProviders, isLoading } = useAuthProviders();
  const hasDuplicateProviders = hasDuplicates(data?.data || [], 'type');

  const { mutate: updateAuthProvider, isLoading: isUpdating } = useUpdateAuthProvider({
    onSuccess: () => {
      setIsModalOpen(false);
      setIsSuccessModalOpen(true);
      setHasProviderChanged(false);
      refetchAuthProviders();
    },
  });
  const providers = data?.data;
  const defaultProvider = providers?.find(provider => provider.is_default);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [authProviderUpdateData, setAuthProviderUpdateData] = useState<ProviderUpdatePayload>({
    id: '',
    type: null,
  });

  const onProviderChange = (payload: ProviderUpdatePayload) => {
    if (payload.id !== authProviderUpdateData?.id) {
      setHasProviderChanged(payload.id !== defaultProvider?.id);
      setAuthProviderUpdateData(payload);
    }
  };

  const onProviderChangeConfirm = () => {
    updateAuthProvider({ id: authProviderUpdateData.id, type: authProviderUpdateData.type, is_default: true });
  };

  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col w-[80%]">
        <div className="flex self-center flex-col mb-6 w-full">
          <DidomiHintbox titleText="Select your default provider for all your widgets" variant="neutral">
            This applies to all widgets except for those you set a authentication provider.
          </DidomiHintbox>

          {hasDuplicateProviders && (
            <DidomiHintbox
              className="mt-6"
              data-testid={'duplicate-provider-hintbox'}
              iconName={'warning'}
              titleText={`It seems you have 2 or more than 2 providers of the same type, consider deleting the duplicated providers. `}
              variant="warning"
            ></DidomiHintbox>
          )}
        </div>
        {isLoading && (
          <>
            <DidomiSkeleton
              isLoading={true}
              data-testid={'radio-card-skeleton-1'}
              className="h-[150px] w-full rounded-[8px] mb-2"
            ></DidomiSkeleton>

            <DidomiSkeleton
              isLoading={true}
              data-testid={'radio-card-skeleton-2'}
              className="h-[150px] w-full rounded-[8px]"
            ></DidomiSkeleton>
          </>
        )}
        <DidomiRadioGroup value={authProviderUpdateData.id || defaultProvider?.id}>
          {providers?.map(provider => (
            <DidomiSelectionBlock
              className="w-full mb-2 cursor-pointer"
              key={provider.id}
              selected={authProviderUpdateData.id === provider.id || (!authProviderUpdateData.id && provider.is_default)}
            >
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                data-testid={`radio-block-${provider.type}`}
                className="flex just-between"
                onClick={() => onProviderChange({ id: provider.id, type: provider.type })}
              >
                <div className="flex flex-col w-full text-primary-blue-6">
                  <div className="w-full flex items-center justify-start mb-xs">
                    <span className=" text-h3 mr-2 font-bold font-serif">{AUTH_TYPE_LABEL_MAP[provider.type].name}</span>
                    {AUTH_TYPE_LABEL_MAP[provider.type].recommended && <DidomiChip label={'Recommended'}></DidomiChip>}
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <span className="font-semibold mb-1 text-secondary-cobalt-blue-3">{provider.id}</span>
                  </div>
                  <div className="text-body-medium">{AUTH_TYPE_LABEL_MAP[provider.type].description}</div>
                </div>
                <DidomiRadio
                  value={provider.id}
                  data-testid={`radio-item-${provider.type}`}
                  radioId={`radio-item-${provider.type}`}
                ></DidomiRadio>
              </div>
            </DidomiSelectionBlock>
          ))}
        </DidomiRadioGroup>
        <ChangeDefaultProviderModal
          isOpen={isModalOpen && !isUpdating}
          authType={authProviderUpdateData.type}
          handleOK={onProviderChangeConfirm}
          handleCancel={() => setIsModalOpen(false)}
        ></ChangeDefaultProviderModal>

        <LoadingModal title={'Saving..'} isOpen={isUpdating}></LoadingModal>

        <ChangeDefaultProviderSuccessModal
          isOpen={isSuccessModalOpen}
          handleClose={() => setIsSuccessModalOpen(false)}
        ></ChangeDefaultProviderSuccessModal>

        <ActionsSlot>
          <DidomiButton data-testid="apply-button" variant="top" disabled={!hasProviderChanged} onClick={() => setIsModalOpen(true)}>
            Apply
          </DidomiButton>
        </ActionsSlot>
      </div>
    </div>
  );
};
