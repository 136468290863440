import { useHasAccessPolicies } from '@didomi/utility-react';
import { adminAccessPolicy } from '@didomi/authorization-metadata';

const ACCESS_POLICIES_CONFIG = {
  ADMIN: [adminAccessPolicy.id],
};

export const useAccessPolicies = () => {
  const { hasAccess: hasAdminAccess, isLoading: isLoadingAdminAccess } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.ADMIN);

  return {
    hasAdminAccess,
    isLoading: isLoadingAdminAccess,
  };
};
