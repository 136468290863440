import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

export const AuthenticationSettingsConfiguration = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    // TODO this page is disabled for now
    navigate('/');
  }, [navigate]);
  return (
    <div>
      <p>Configuration page</p>
    </div>
  );
};
