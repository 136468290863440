import { AuthProviderType } from '@enums';

export const AUTH_TYPE_LABEL_MAP: Record<AuthProviderType, { name: string; description: string; recommended: boolean }> = {
  [AuthProviderType.MESSAGE]: {
    name: 'Magic Link',
    description:
      // eslint-disable-next-line max-len
      'Magic Link involves requesting the end user’s email address and sending them an email containing a link that will grant them access to the widget.',
    recommended: false,
  },
  [AuthProviderType.OTP]: {
    name: 'One Time Password',
    description:
      // eslint-disable-next-line max-len
      "It's an automatically generated numeric or alphanumeric string of characters that authenticates a user for a single transaction or login session.",
    recommended: true,
  },
  [AuthProviderType.OAUTH2]: {
    name: 'OAuth2',
    description:
      // eslint-disable-next-line max-len
      'OAuth 2.0 is not supported by our widgets yet and requires a custom integration. If you have created an OAuth 2.0 provider, you should already be in contact with our support.',
    recommended: false,
  },
};
