import React from 'react';
import { DidomiModal, DidomiModalHeader, DidomiButton, DidomiIllustration, DidomiModalActions } from '@didomi/ui-atoms-react';
import { AuthProviderType } from '../enums/auth-provider.enum';
import { AUTH_TYPE_LABEL_MAP } from '../constants/auth.constants';

interface ChangeDefaultProviderModalProps {
  isOpen: boolean;
  handleOK: () => void;
  handleCancel: () => void;
  authType: AuthProviderType;
}

export const ChangeDefaultProviderModal = ({ handleOK, handleCancel, authType, isOpen }: ChangeDefaultProviderModalProps): JSX.Element => {
  return (
    <DidomiModal className="relative" isOpen={isOpen} permanent="true" variant="slim" data-testid={'change-default-provider-modal'}>
      <DidomiIllustration name={'warning-modal'} className="mb-10"></DidomiIllustration>
      <DidomiModalHeader
        variant="warning"
        modalSubTitle={'Important'}
        modalTitle={'Placeholder'}
        modalDescription={
          // eslint-disable-next-line max-len
          'Please note that this change will impact the behavior of all widgets having authentication enabled, except for those that have a provider ID specified. We highly recommend checking your widgets to ensure that the new provider has been correctly applied.'
        }
      >
        <div slot="modal-title">
          <h3 className="h3 text-primary-blue-6">
            You are going to apply <b>{AUTH_TYPE_LABEL_MAP[authType]?.name} authentication </b> for all your widgets.
          </h3>
        </div>
      </DidomiModalHeader>
      <DidomiModalActions className="pt-5!">
        <DidomiButton variant="secondary" onClick={handleCancel}>
          No, thanks
        </DidomiButton>
        <DidomiButton onClick={handleOK}>
          <div className="w-full">Yes, continue</div>
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
